import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import logo from '../../../images/Goldi.gif';

export const FeastPage = () => {
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  const splash_screen = {
    backgroundColor: theme.palette.primary.dark,
    height: '100vh',
    width: '100%',
    display: 'flex',
    position: 'fixed',
    padding: 9,
    zIndex: 999999,
    opacity: .9
  }

  const img_style = {
    height: 80,
    width: 80,
    display: 'initial'
  }

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  }, []);

  return (
    <Box sx={splash_screen} maxWidth='sm'>
      <Grid container sx={{margin: 'auto'}}>
        <Grid item xs={12} textAlign={'center'}>
           <img src={logo} style={img_style} />
           <Typography variant='h6' color='#FFF' textAlign='center'>
            Hold tight while we customize your dishes...
          </Typography>
        </Grid>
      </Grid> 
    </Box>
  );
}
