import { auth } from 'firebase';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  updatePassword,
  verifyPasswordResetCode,
} from 'firebase/auth';

export type NewUser = {
  name: string;
  email: string | null;
  phoneNumber: string;
  id: string;
};

import axios from 'axios';
import type { User } from 'firebase/auth';
import { updateProfile } from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

export interface IAuthService {
  [key: string]: any; //TODO: define interface
}

export enum ROLES {
  USER = 'user',
  MERCHANT = 'merchant',
  ADMIN = 'admin',
}

export enum ROLE_TYPES {
  USER = 'User',
  SUPER_ADMIN = 'SuperAdmin',
  MERCHANT_ADMIN = 'MerchantAdmin',
  RESTAURANT_MANAGER = 'RestaurantManager',
  ORDER_ADMIN = 'OrderAdmin'
}

export const login = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence
  );

  return signInWithEmailAndPassword(auth, email, password);
};

export const signup = async (
  email: string,
  password: string,
  name: string,
  phoneNumber: string,
  rememberMe?: boolean
) => {
  const auth = getAuth();
  const db = getFirestore();

  try {
    await setPersistence(
      auth,
      rememberMe ? browserLocalPersistence : browserSessionPersistence
    );

    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    await updateProfile(user, {
      displayName: name,
    });

    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      displayName: name,
      phoneNumber: phoneNumber,
      role: ROLES.USER,
      roleType: ROLE_TYPES.USER,
      createdAt: new Date(),
    });
    return user;
  } catch (err) {
    console.log(err);
  }
};

export const logout = () => {
  signOut(auth);
  window.location.assign('/');
};

export const logoutWithoutRedirect = (url: string) => {
  signOut(auth);
  window.location.assign(url)
}

export const sendResetPasswordEmail = async (email: string) => {
  try {
    sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error(error);
  }
};

export const verifyResetPasswordCode = async (code?: string) => {
  if (!code) return false;
  try {
    await verifyPasswordResetCode(auth, code);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const resetPassword = async (code: string, newPassword: string) => {
  try {
    await confirmPasswordReset(auth, code, newPassword);
  } catch (error) {
    console.error(error);
  }
};

// TODO: this doesn't need to be async
export const getCurrentUser = (): User | null => {
  return getAuth().currentUser;
};

// TODO: refactor this into a provider
export const getCurrentUserRole = async (): Promise<any> => {
  const { currentUser } = getAuth();

  if (currentUser) {
    try {
      const { claims } = await currentUser.getIdTokenResult();
      const { role, roleType } = claims;
      return {
        role,
        roleType,
      };
    } catch (e) {
      AuthService.logout();
    }
  } else {
    AuthService.logout();
  }
};

export const getUser = async () => {
  // /users/user/{consumerId}
  const token = await getToken();
  //const consumerId = FirebaseManager.auth.currentUserID();
  const response = await axios.get(`/users/user` , { headers: {
    'Authorization': `Bearer ${token}`
  }})
  return response.data;
}


export const updateUser = async (user: NewUser) => {
  const token = await getToken();
  const userPayload = {
    consumerId: user.id,
    name: user.name,
    email: user.email,
    phoneNumber: user.phoneNumber,
    isOnboarded: true
    
  };
  console.log('userPayload', userPayload);
  console.log('token', token);
  const response = await axios.post('/users/updateUser', userPayload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log('userPaylod response', response.data);

  return response.data;
};

export const getToken = async (): Promise<string | undefined> => {
  const { currentUser } = getAuth();

  if (currentUser) {
    try {
      const tokenResult = await currentUser.getIdTokenResult();
      // console.log({tokenResult});
      return getIdToken(currentUser);
    } catch (e) {
      AuthService.logout();
    }
  } else {
    AuthService.logout();
  }
};

export const getInvites = async () => {
  const currentUser = getCurrentUser();
  if (currentUser) {
    try {
      const token = await currentUser.getIdToken();
      const response = await axios.get(`/invites`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  } else {
    console.log('No user found');
  }
};

export const isAdmin = async (): Promise<boolean> => {
  const { role } = await getCurrentUserRole();
  return role === ROLES.ADMIN;
};

export const signInWithLink = async (link: string) => {
  const encodedEmail = new URL(link).searchParams.get('email');
  const decodedEmail = atob(encodedEmail || '');
  return signInWithEmailLink(auth, decodedEmail, link);
};

export const isValidSignInLink = (link: string) => {
  return isSignInWithEmailLink(auth, link);
};

export const changePassword = async (password: string) => {
  const currentUser = getCurrentUser();
  if (currentUser) {
    await updatePassword(currentUser, password);
  }
};

const AuthService = {
  login,
  logout,
  logoutWithoutRedirect,
  sendResetPasswordEmail,
  verifyResetPasswordCode,
  resetPassword,
  getCurrentUser,
  getToken,
  getCurrentUserRole,
  isAdmin,
  signInWithLink,
  isValidSignInLink,
  changePassword,
  signup,
  getInvites,
  getUser,
  updateUser,
};

export default AuthService;
