import { Box, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useEffect, useState } from 'react';
import logo from '../../../images/fall_back-menu.svg';


export enum PreferenceType {
  allergy,
  diet,
}

type Props = {
  preferenceType: PreferenceType;
};

const SelectPreferences = ({ preferenceType }: Props) => {
  const {
    allergies,
    diets,
    selectedAllergies,
    selectedDiets,
    toggleSelectedAllergy,
    toggleSelectedDiet,
  } = useDietaryPreferences();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageProp = {
    height: '47.5px',
    width: '47.5px',
    display: 'inline-block',
  };

  const title =
    preferenceType === PreferenceType.allergy ? 'Any foods you dislike?' : 'Awesome! What’s your diet?';
    const subtitle =
    preferenceType === PreferenceType.allergy ? 'Tell us what to skip so we only show dishes you’ll love.' : '';
  const preferences =
    (preferenceType === PreferenceType.allergy ? allergies : diets) ?? [];
  const selectedPreferences =
    preferenceType === PreferenceType.allergy
      ? selectedAllergies
      : selectedDiets;

  useEffect(() => {
    if (
      selectedPreferences.length > 1 &&
      preferenceType !== PreferenceType.allergy
    ) {
      const lastPreference: string =
        selectedPreferences[selectedPreferences.length - 1];
      onToggle(lastPreference);
      setError('You can select 1 ');
    }
  }, [selectedPreferences]);

  const onToggle =
    preferenceType === PreferenceType.allergy
      ? toggleSelectedAllergy
      : toggleSelectedDiet;

  const [filteredPreferences, setFilteredPreferences] = useState(
    () => preferences
  );

  const addDefaultSrc = (ev: any) => {
    ev.target.src = logo;
  };

  useEffect(() => {
    if (searchTerm.trim().length === 0) {
      setFilteredPreferences(preferences);
    } else {
      setFilteredPreferences(
        preferences.filter(({ name }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, preferences]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    setIsScrolledToBottom(scrollHeight - scrollTop === clientHeight);
  };

  const fontColor = theme.palette.secondary.light;

  return (
    <Stack sx={{ pb: 6, position: 'relative' }}>
      <Typography variant="h5" color={theme.palette.secondary.main}>
        {title}
      </Typography>
      <Typography variant="subtitle1" color={theme.palette.secondary.main} sx={{ marginBottom: theme.spacing(2) }}>
        {subtitle}
      </Typography>
      {error && (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.chart.red }}
        >
          {error}
        </Typography>
      )}

      <Box
        sx={{
          maxHeight: '600px', // Limit the height to show the scroll area
          overflowY: 'auto',
          position: 'relative',
        }}
        onScroll={handleScroll}
      >
        <Grid container alignItems="start" spacing={2}>
          {filteredPreferences.map(
            ({ id, name, key, filledIconUrl, unfilledIconUrl }) => (
              <Grid
                onClick={() => onToggle(key)}
                key={id}
                item
                xs={4}
                sm={4}
                md={4}
              >
                <Box
                  sx={{ position: 'relative', textAlign: 'center', padding: 1 }}
                >
                  <img
                    onError={addDefaultSrc}
                    src={
                      selectedPreferences.indexOf(key) > -1
                        ? filledIconUrl
                        : unfilledIconUrl
                    }
                    style={imageProp}
                    alt={name}
                  />
                  <Typography
                    mt={1}
                    color={fontColor}
                    sx={
                      selectedPreferences.indexOf(key) > -1
                        ? {
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: '50px',
                            padding: '0.5em',
                          }
                        : {
                            backgroundColor: theme.palette.grey[0],
                            padding: '0.5em',
                          }
                    }
                    variant="subtitle2"
                  >
                    {name}
                  </Typography>
                </Box>
              </Grid>
            )
          )}
        </Grid>
      </Box>

      {/* Gradient and Scroll Indicator */}
      {!isScrolledToBottom && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '50px',
            background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.secondary.main }}
          >
            Scroll for more ▼
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default SelectPreferences;
