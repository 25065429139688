import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LogoutIcon from '@mui/icons-material/Logout';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { auth } from 'firebase';
import { Widget } from 'interfaces/WidgetInterface';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { logoutWithoutRedirect } from 'services/auth.service';
import { useCart } from '../context/CartContext';
import { OrderType } from '../model/cart';
import { UserOrderItem } from './UserOrderItem';

interface Props {
  isEditable: boolean;
}

export const UserOrderDetails = ({ isEditable }: Props) => {
  const { cart, orders } = useCart();
  const navigate = useNavigate();
  const cartObj = isEditable ? cart : orders.cart;
  const { restaurantId } = useParams();
  const theme = useTheme();
  const [instruction, setInstruction] = useState('');
  const [orderType, setOrderType] = useState(OrderType.InRestaurant);
  const [user, loading] = useAuthState(auth);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [widget, setWidget] = useState<Widget>(Widget.Login)

  useEffect(() => {
    if (!loading) {
      setLoggedIn(!!user);
    }
  }, [loading, user]);

  useEffect(() => {
    console.log('Rendering UserOrderDetails, widget:', widget, 'isLoggedIn:', isLoggedIn);
  }, [widget, isLoggedIn]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (loadingIndicator) {
      timeoutId = setTimeout(() => {
        setLoadingIndicator(false);
      }, 4000);
    }

    return () => clearTimeout(timeoutId);
  }, [loadingIndicator]);

  const orderHeader = {
    position: 'fixed',
    pl: 2,
    pt: 1.5,
    top: 0,
    width: "100%",
    color: theme.palette.grey[0],
    zIndex: 99999,
  };

  const orderHeader2 = {
    position: 'fixed',
    py: 1.5,
    top: 0,
    width: "100%",
    zIndex: 999,
    textAlign: 'center',
    color: theme.palette.grey[0],
    backgroundColor: "#fe8154;",
  };

  useEffect(() => {
    setInstruction(isEditable ? '' : orders.notes);
  }, [orders, isEditable]);

  const goToLink = () => {
    if (restaurantId) {
      navigate('/explore/' + restaurantId + '/menu');
    } else {
      navigate(-1);
    }
  };

  return (
    <Stack sx={{ backgroundColor: theme.palette.grey[0] }}>
      <Box sx={orderHeader} maxWidth='sm'>
  <ArrowBackIosNewIcon onClick={goToLink} sx={{ cursor: 'pointer' }} />
</Box>
<Box sx={orderHeader2} maxWidth='sm'>
  <Typography variant='body1' sx={{ fontSize: 18 }}>
    Order
  </Typography>
  <Box sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}>
    <Button
      variant='contained'
      size='small'
      style={{
        background: 'transparent', // Transparent background for a clean look
        borderRadius: '4px',
        padding: '8px 16px',
        cursor: 'pointer',
        color: theme.palette.grey[0],
        fontSize: '16px',
      }}
      onClick={()=>{
        logoutWithoutRedirect('/explore/'.concat(restaurantId ? restaurantId : '/'))
      }}
    >
      <LogoutIcon/>
    </Button>
  </Box>
</Box>
      {cartObj && cartObj.orders.length ? (
        <>
          {cartObj.orders.map((order) => (
            <UserOrderItem 
              instruction={instruction} 
              setInstruction={setInstruction} 
              key={`row-${order.userId}`} 
              orderType={orderType} 
              order={order} 
              isEditable={isEditable} 
            />
          ))}
        </>
      ) : (
        <Stack alignItems='center' spacing={2} mt={5}>
          <NoMealsIcon />
          <Typography variant='body1'>Your Cart is Empty.</Typography>
        </Stack>
      )}

      {loadingIndicator && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <img src="/loggedIn.gif" alt="Loading..." />
        </Box>
      )}

      {/* {!isLoggedIn && !loadingIndicator && widget == Widget.Login && (
        <LoginWidget 
          setLoadingIndicator={setLoadingIndicator} 
          setLoggedIn={setLoggedIn} 
          isLoggedIn={isLoggedIn} 
          setWidget={setWidget}
        />
      )}
      
      {!isLoggedIn && !loadingIndicator && widget == Widget.SignUp && (
        <RegisterWidget 
          setLoadingIndicator={setLoadingIndicator} 
          setLoggedIn={setLoggedIn} 
          isLoggedIn={isLoggedIn} 
          setWidget={setWidget}
        />
      )}

      {!isLoggedIn && !loadingIndicator && widget == Widget.ForgotPassword && (
        <ForgotPasswordWidget
          setLoadingIndicator={setLoadingIndicator}
          setWidget={setWidget}
        />
      )} */}

    </Stack>
  );
};
