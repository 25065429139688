import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useState } from 'react';
import intro from '../../../../images/onboarding-intro.svg';
import { PrimaryActionButton } from '../components/PrimaryActionButton';

interface Props {
  changeStep(val: number, index: boolean): void; // Update return type if possible
  stepValue: number;
}

export default function Intro({ stepValue, changeStep }: Props) {
  const theme = useTheme();
  const { allergies, diets, username, setUsernameHandler } = useDietaryPreferences();
  const [name, setName] = useState(username);

  const saveName = (stepValue: number) => {
    setUsernameHandler(name);
    changeStep(stepValue, false);
  };

  const styles = {
    introContainer: {
      width: '100%',
      position: 'relative',
      backgroundImage: `url(${intro})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      paddingBottom: theme.spacing(0),
    },
    imageProps: {
      height: '50px',
      width: '50px',
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    selectedTab: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 50,
      color: theme.palette.primary.lighter,
      textAlign: 'center',
      width: '100px',
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4),
    },
    introContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(8),
      paddingTop: theme.spacing(8),
    },
    buttonContainer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <Box sx={styles.introContainer}>
      <Grid container sx={styles.introContent}>
        <Grid item xs={6} />
        <Grid
          item
          xs={6}
          sx={{
            textAlign: 'center',
            paddingRight: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
         
          <Typography variant="h3" sx={{ marginTop: 0, textAlign: 'right' ,lineHeight: 1.5, }}>
          Let’s get a menu made just for you 
          in under a minute.
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.grey[0], width: '100%' }} mb={1}>
        {/* <Grid container>
          <Grid item xs={6} p={2} sx={{ display: 'inline-grid', alignContent: 'center' }}>
            <Typography variant="h3" sx={{ marginTop: 0, textAlign: 'center' }}>
              Personalized
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 0, textAlign: 'center' }}>
              Goldi makes it easy for everyone to order what they want
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            {allergies && (
              <Box sx={styles.gridContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/goldi-fe3e4.appspot.com/o/images%2Fgoals%2Fwhole_30_unfilled.png?alt=media"
                  alt="Whole 30 dietary preference"
                  style={styles.imageProps}
                />
                <Typography
                  color={theme.palette.secondary.light}
                  sx={styles.selectedTab}
                  variant="subtitle2"
                >
                  Whole 30
                </Typography>
              </Box>
            )}
            {diets && (
              <Box sx={styles.gridContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/goldi-fe3e4.appspot.com/o/images%2Fallergies%2Fonion_filled.png?alt=media"
                  alt="Onion allergy"
                  style={styles.imageProps}
                />
                <Typography
                  color={theme.palette.secondary.light}
                  sx={styles.selectedTab}
                  variant="subtitle2"
                >
                  Onions
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid> */}
        <Box  sx={{
            ...styles.buttonContainer,
            marginTop: theme.spacing(2), // Adjusted spacing from the question
            paddingBottom: theme.spacing(8), // Additional space below buttons
            flexDirection: 'column', // Ensures question and buttons are in a single column
            alignItems: 'center',
            textAlign: 'center',
          }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h3" sx={{ marginBottom: theme.spacing(24)  }}>
            Do you follow a specific diet?
          </Typography>
        <PrimaryActionButton title="Yes, I do" onClick={() => saveName(1)} />
          
        </Grid>
        <Grid item xs={12}>
        <PrimaryActionButton title="Nope" onClick={() => saveName(2)} />
        </Grid>
      </Grid>
    </Box>
       

      </Box>
    </Box>
  );
}
