import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryActionButton } from '../components/PrimaryActionButton';

interface Props {
  changeStep (val: number): any;
  tab: number,
  selectedPreferencesCount: number
}


export default function Footer({tab, selectedPreferencesCount, changeStep} : Props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const hash = window.location.hash;
  const navigate = useNavigate();

  const tabsProperties = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: selectedPreferencesCount > 0 ? 120 : 64,
    left: 0, 
    right: 0,
    textAlign: "center",
  };

  const boxProperties = {
    width: '100%', 
    backgroundColor: theme.palette.primary.lighter,
    position: 'fixed',
    bottom: 0,
    padding: 2,
    textAlign: "center",
    boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.2)',
    display: 'flex-box',
    flexDirection: 'column'
  }

  return (
    <>
      {tab > 0 && <Box sx={boxProperties} maxWidth='sm'>
        <Box mb={1}>
          <PrimaryActionButton title={tab == 1 ? 'Save & Continue' : 'See My Menu!'} onClick={() => { changeStep(tab + 1) }} />
          {tab == 2 && <Box mt={1}><Typography textAlign='center' component={'div'} variant="caption" sx={{fontStyle: 'italic'}}> By seeing my recommendations, I agree to <br />
          <u onClick={() => navigate('tnc')}>Goldi’s terms and conditions</u> </Typography></Box>}
        </Box>
      </Box>}
    </>
  );
}
