import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Container } from '@mui/material';
import closeIcon from 'images/close.svg';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFormError from 'components/CustomFormError';
import CustomLink from 'components/CustomLink';
import CustomTextField from 'components/CustomTextField';
import CustomTypography from 'components/Typography';
import AuthService, { getUser } from 'services/auth.service';
import * as ROUTES from 'Utils/constants/routes';
import { loginSchema } from 'Utils/Schemas/Login.schema';
import { LoginForm, LoginOptions } from './index.styles';

const closeIconProperties = {
  height: '30px',
  width: '30px',
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
};

interface Props {
  onClose: () => void;
  redirectURI: string;
}

type LoginFormFields = {
  email: string;
  password: string;
};

const Login = ({ onClose, redirectURI }: Props) => {
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [unsuccessfulLogin, setUnsuccessfulLogin] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<LoginFormFields>({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const formFields = useWatch({
    control,
    name: ['email', 'password'],
  });

  useEffect(() => {
    setUnsuccessfulLogin(false);
  }, [formFields]);

  const onLogin = async (data: LoginFormFields) => {
    try {
      await AuthService.login(data.email, data.password, rememberMe);
      const redirectUri = urlSearchParams.get('redirect_uri');
      const res = await getUser();
      let user = null      
      if(res.success){
        user = res.user
      }
      console.log(redirectURI)
      console.log(user)

      if (redirectURI) {
        if (!user.allergies && !user.goals) {
          window.location.assign(redirectURI.concat('/onboarding'));
        } else {
          window.location.assign(redirectURI.concat('/menu'));
        }
      }
      if (redirectUri) {
        window.location.assign(redirectUri);
      } else {
        navigate(redirectURI);
      }
    } catch (error) {
      console.error(error);
      setUnsuccessfulLogin(true);
    }
  };

  return (
    <Container>
      <Avatar
        src={closeIcon}
        sx={closeIconProperties}
        onClick={() => onClose()}
      />
      <LoginForm>
        <CustomTypography
          fontWeight='bold'
          fontSize='24px'
          lineHeight='26px'
          letterSpacing='-0.015em'
          color='#000000'
          marginBottom={'37px'}
        >
          Log in to Goldi
        </CustomTypography>

        <CustomTextField
          id='login-email'
          label='Email Address'
          register={register}
          fieldName='email'
          errors={errors}
        />

        <CustomTextField
          id='login-password'
          label='Password'
          register={register}
          fieldName='password'
          errors={errors}
          type={showPassword ? 'text' : 'password'}
          endAdornmentText={showPassword ? 'HIDE' : 'SHOW'}
          handleClickEndAdornment={() => setShowPassword(!showPassword)}
        />

        <LoginOptions sx={{ paddingTop: errors?.password ? '8px' : '4px' }}>
          <CustomCheckbox
            checked={rememberMe}
            label='Remember Me'
            onChange={() => setRememberMe(!rememberMe)}
            styles={{ marginLeft: 0 }}
          />

          <CustomLink
            onClick={() =>
              navigate(ROUTES.FORGOT_PASSWORD, {
                state: {
                  email: getValues('email'),
                },
              })
            }
          >
            Forgot Password?
          </CustomLink>
        </LoginOptions>

        {unsuccessfulLogin && (
          <CustomFormError errorText='Incorrect email or password' />
        )}

        <CustomButton
          onClick={handleSubmit(onLogin)}
          disabled={!isValid}
          width='100%'
        >
          Login
        </CustomButton>
      </LoginForm>
    </Container>
  );
};

export default Login;
