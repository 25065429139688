import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import logo from '../../../../images/Goldi-Animation.svg';
import GetStarted from './GetStarted';

interface Props {
  setVideoPlayed(val: boolean): any;
}

const Splash = ({ setVideoPlayed }: Props) => {
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  const splashScreenStyles = {
    backgroundColor: theme.palette.primary.lighter,
    height: '100vh', // Full viewport height
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensures buttons stay at the bottom
    padding: theme.spacing(2), // Padding for better spacing
    boxSizing: 'border-box',
  };

  const logoTextContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 'auto', // Moves content down
    paddingTop: theme.spacing(0), // Adds spacing above the logo and text
  };

  const buttonContainerStyles = {
    marginBottom: theme.spacing(60), // Spacing below the buttons
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  }, []);

  return (
    <Box sx={splashScreenStyles}>
      <Box sx={logoTextContainerStyles}>
        <img
          src={logo}
          alt="Goldi Logo"
          style={{ marginBottom: theme.spacing(14), maxWidth: '100%', height: 'auto' }}
        />
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            maxWidth: '330px',
            lineHeight: 1.0,
            textAlign: 'center',
            marginBottom:6
          }}
        >
          The easiest way to personalize your food order, so that it’s Just Right.
        </Typography>
        
        
        <Typography
          variant="h3"
          fontWeight="normal"
          sx={{
            maxWidth: '350px',
            lineHeight: 1.0,
            textAlign: 'center',
            marginBottom:6
          }}
        >
          Answer a couple of quick questions 
          to get your menu.
        </Typography>
        {/* <Typography
          variant="h3"
          fontWeight="normal"
          sx={{
            maxWidth: '350px',
            lineHeight: 1.0,
            textAlign: 'center',
            marginBottom:6
          }}
        >
          Let’s get a menu made just for you 
           in under a minute.
        </Typography> */}
      </Box>
      <Box sx={buttonContainerStyles}>
        {checked && <GetStarted setVideoPlayed={setVideoPlayed} />}
      </Box>
    </Box>
  );
};

export default Splash;
